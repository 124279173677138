<style scoped>
.pagebgimg{
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100%;height: 100%;
  }
  .pagebgimg>img{
    width: 100%;height: 100%;
  }
</style>
<template>
  <div class="members-chapter">
    <div class="header">
      <div class="return_top">
        <img @click="return_top" width="24" height="24" src="../../assets/image/return_top2.png" alt="">
      </div>
      <div class="middle_title">
        <p class="username">{{rolename}}</p>
        <p class="chapter">共{{chaptersize}}章</p>
      </div>
    </div>
    <ul class="center_box">
      <li class="center_box_li" v-for="item in chapterList" :key="item.index">
        <div class="center_box_li_top">
          <div class="center_box_li_top_left">
            {{item.chapterTitle}}
          </div>
          <div class="center_box_li_top_right">
            字数：{{item.chapterNumber}}
          </div>
        </div>
        <div class="manage-tabcon">
          <div class="chapter-list">
            <div class="chapter-box">
              <div class="chapter-box-btn">
                <label v-show="item.showType === 0"><i class="icon_type01"></i>半身像</label>
                <label v-show="item.showType === 1"><i class="icon_type02"></i>胸像</label>
                <label v-show="item.showType === 2"><i class="icon_type03"></i>网聊</label>
                <button class="chapter-setcon" v-show="item.ownFlag === 1"><img src="../../assets/image/icon_con.png" height="18">内容创作</button>
                <button class="chapter-setcon" disabled="disabled" v-show="item.ownFlag === 0"><img src="../../assets/image/icon_con.png" height="18">内容创作</button>
                <!-- <button class="chapter-set" disabled><img src="../../assets/image/icon_con.png"
                    height="18">内容创作</button> -->
                <button class="chapter-set button" v-show="item.ownFlag === 1"><img src="../../assets/image/icon_set.png" height="18">章节设置</button>
                <button class="chapter-set button" disabled="disabled" v-show="item.ownFlag === 0"><img src="../../assets/image/icon_set.png" height="18">章节设置</button>
                <!-- <button class="chapter-set" disabled><img src="../../assets/image/icon_set.png"
                    height="18">章节设置</button> -->
                <button class="button"><img src="../../assets/image/icon_read.png" height="16">本章试读</button>
                <!-- <a class="chapter-del"></a> -->
                <!--<button v-if="bookInfo.isVerify === 0" class="chapter-set" @click="setChapter(item.id)"><img src="../../assets/image/icon_set.png" height="18">章节设置</button>-->
                <!--<button v-else class="chapter-set" disabled><img src="../../assets/image/icon_set.png" height="18">章节设置</button>-->
                <!--<button @click="goLook(item.id)"><img src="../../assets/image/icon_read.png" height="16">本章试读</button>-->
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      ugcBook: {
        authorId: null,
        chapterSize: null,
        id: null,
        isVerify: null,
        sumWords: null
      },
      rolename: null,
      chaptersize: null,
      chapterList: null,
      bookInfo: null

    }
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    this.getchapterSize()
  },
  mounted () {
    // 解决加载bug，加载不完全问题
    if (location.href.indexOf('#reloaded') === -1) {
      location.href = location.href + '#reloaded'
      location.reload()
    }
  },
  methods: {
    // 进入共创者的作品页面
    getchapterSize () {
      this.rolename = this.$route.query.rolename
      this.chaptersize = this.$route.query.chaptersize
      this.axios({
        method: 'get',
        url: '/v1/ugcChapterList',
        params: {
          ugcBookId: this.$route.query.bookId,
          cUserId: this.$route.query.cUserId
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.head.flag === 0) {
          if (res.data.content.chapterList.length === 0) {
            // console.log(123)
          } else if (res.data.content.chapterList.length > 0) {
            this.chapterList = res.data.content.chapterList
            this.bookInfo = res.data.content.ugcBook
          }
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 返回按钮
    return_top () {
      this.$router.go(-2)
    }
  }
}
</script>

<style scoped="">
  .center_box_li:not(:first-child){
    margin-top: 15px;
  }
  .center_box{
    /* border-bottom: 1px solid #EEEEEE; */
  }
  .manage-tabcon{
    margin-top: 11px;
  }
  /* 样式 */
  @media screen and (max-width: 340px) {
      .button{
          margin-left: 0px!important;
          /* padding: 0 3px; */
      }
  }
  @media only screen and (max-width: 340px){

  }
  .chapter-box{
    /* padding: 10px 15px 5px; */
    /* border-bottom: 1px solid #eee; */
    font-size: 0.9rem;
    position: relative;
  }
  .chapter-box .chapter-del{
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 24px;
    background: url("../../assets/image/icon_del03.png") top right no-repeat;
    background-size: 100% 100%;
  }
  .chapter-box .chapter-box-info{
    margin-bottom: 15px;
  }
  .chapter-box .chapter-box-info .fr{
    margin-right: 15px;
  }
  .chapter-box .chapter-box-btn{
    text-align: right;
  }
  .chapter-box .chapter-box-btn label{
    float: left;
    position: relative;
    top: 4px;
    padding-left: 22px;
    color: #666;
    font-size: 0.7rem;
  }
  .chapter-box .chapter-box-btn label i{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
    background: url("../../assets/image/icon_type.png");
    background-size: 54px 18px;
  }
  .chapter-box .chapter-box-btn label i.icon_type01{
    background-position: 0 0;
  }
  .chapter-box .chapter-box-btn label i.icon_type02{
    background-position: -18px 0;
  }
  .chapter-box .chapter-box-btn label i.icon_type03{
    background-position: -36px 0;
  }
  .chapter-box .chapter-box-btn button{
    margin-left: 10px;
    background: transparent;
    line-height: 22px;
    height: 24px;
    padding: 0 5px;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    color: #666;
    font-size: 0.75rem;
  }
  .chapter-box .chapter-box-btn button:first-of-type{
    margin-left: 0;
  }
  .btn-bottom-box button:disabled{
    background: #9fa5f1;
    opacity: 1;
    color:rgba(255,255,255,0.7);
    box-shadow: 0px 0px 10px 3px rgba(199, 203, 254, 0.5);
  }
  .chapter-box .chapter-box-btn button img{
    height: 13px;
    vertical-align: middle;
    margin-right: 3px;
    position: relative;
    top: -1px;
  }
  /* 结束 */
  .center_box_li_top_left {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
  }

  .center_box_li_top_right {
    float: right !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }

  .center_box_li_top {
    overflow: hidden;
  }

  .center_box_li_top>div {
    float: left;
  }
.center_box_li{
  padding: 0 15px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 10px;

}
  .center_box {
    padding: 12px 0px 6px;
  }

  .members-chapter {}

  .header {
    padding: 8px 10px 4px;
    overflow: hidden;
    border-bottom: 1px solid #EEEEEE;
  }

  .header>div {
    float: left;
  }

  .return_top>img {
    margin: 5px 0;
  }

  .middle_title {
    text-align: center;
    float: none !important;
  }

  .username {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-right: 12px;
  }

  .chapter {
    font-size: 10px;
    font-weight: 400;
    color: #666666;
    margin-right: 12px;
  }
</style>
